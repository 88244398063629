import { getColor } from './chartsColors'

const packDatasets = source => ({
  labels: source.labels,
  datasets: source.data?.map((data, index) => ({
    ...data,
    backgroundColor: getColor(index),
    barPercentage: 0.4,
    categoryPercentage: 0.5,
  })),
})

export default packDatasets
