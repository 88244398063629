import { gridLabelsColor, gridLinesOptions, dataLabelsColor } from './globalSetups'

export const verticalBarChartOptions = extremeScaleValues => ({
  plugins: {
    datalabels: {
      ...dataLabelsColor,
      align: 'top',
      display: true,
      anchor: 'end',
      
    },
  },
  scales: {
    xAxes: [{
      offset: true,
      ticks: {
        stacked: true,
        fontFamily: 'DIN Pro',
        fontColor: 'white',
        fontSize:28,
      },
      gridLines: { ...gridLinesOptions, display: false },
    }],
    yAxes: [{
      ticks: {
        fontColor: gridLabelsColor,
        fontSize:28,
        suggestedMin: 0,
        suggestedMax: extremeScaleValues.maxValue,
        fontFamily: 'DIN Pro Light',
      },
      gridLines: gridLinesOptions,
    }],
  },
  legend: { display: false },
  tooltips: {
    enabled: true,
    displayColors: false,
  },
  maintainAspectRatio: false,
})

export const verticalBarChartThickness = 50
export const verticalBarChartBorderWidth = 0
