import React from 'react'
import { MoonLoader } from "react-spinners";
import { css } from "@emotion/core";

function Preloader() {
  
    return (
        <MoonLoader
        css={override}
        size={70}
        color={"#123abc"}
      />
    )
  }
  export default Preloader;


  const override = css`
  display: block;
  margin: 0 auto;
  border-color: #0079C1;
`;