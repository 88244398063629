export const chartBackgroundColor = '#191F28'

export const pieChartOptions = {
  plugins: {
    datalabels: { display: false },
  },
  legend: { display: false },
  maintainAspectRatio: false,
  responsive: true,
  tooltips: { enabled: false },
}
