import React from 'react'
import { Pie } from 'react-chartjs-2'
import { pieChartOptions, chartBackgroundColor } from './setups/pieChartSetups'

const PieChart = ({ source, colors }) => {
  const getChartData = () => ({
    datasets: [{
      data: source.map(data => data.value),
      backgroundColor: colors,
      hoverBackgroundColor: colors,
      borderColor: chartBackgroundColor,
      hoverBorderColor: chartBackgroundColor,
      borderWidth: 1,
    }],
  })

  // const legendBlockStyle = {

  // }

  const getMarkerColor = index => ({ backgroundColor: colors[index] })

  const data = getChartData()

  return (
    <div className='pie_chart__container'>
      {source?.length > 0 && (
        <>
          <div className='pie_chart__chart_container'>
            {source.some(item => item.value !== 0)}
            {source.some(item => item.value !== 0)
              ? <Pie data={data} options={pieChartOptions} />
              : <span>Нет данных</span>}
          </div>
          {console.log(colors)}
          <div className='pie_chart__legend_block__content'>
            {source.length > 0 && source.map((data, index) => (
              <div className='pie_chart__legend_item_block' key={data.title}>
                <div className='round_marker' style={getMarkerColor(index)} />
                <span className='pie_chart__legend_title'>{data.title}</span>
                <span className='pie_chart__legend_value'>
                  {`${Number(data.value).toFixed(1)}%`}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default PieChart
