import React, { useState, useEffect } from 'react'
import Connect from './components/Connect'
import LoginPage from './components/LoginPage';
import nanoid from 'nanoid';
import api from './api/index'
import Preloader from './components/Preloader'
import ModalWindow from './components/ModalWindow'



function MainComponent() {

    const [login, setLogin] = useState(undefined);
    const [modalIsOpen,setmodal] = useState(false);
    const [error,setError] = useState('');
    const errorText = 'Ошибка! Пожалуйста проверьте активацию в панели для администратора'

    const changeLoginStatus = () => {
        if (localStorage.getItem('loginStatus') === 'true') {
            setLogin(true)
        }
        else {
            setLogin(false)
        }
    }

    useEffect(() => {
        changeLoginStatus()
    });

    const loginFunc = async () => {
        setError('')
        const id = nanoid(10)
        //console.log(id);

        await api.receive(id).then(res => {
            //console.log(res)
            localStorage.setItem('loginStatus', true);
            localStorage.setItem('uniqId', id);
            setLogin(true)
        })
            .catch((err) => {
                //console.log(err)
                setError(errorText)
            })
    }

    const openModal = () => {
        setmodal(!modalIsOpen);
      }

      const setLoginUnd = () => {
         //localStorage.setItem('uniqId', value);
         setLogin(undefined)
        setmodal(!modalIsOpen);
        console.log(localStorage.getItem('loginStatus'))
        if (localStorage.getItem('loginStatus') !== 'true') {
            localStorage.setItem('loginStatus', true);
            setLogin(true)
        }
      }


    return(
        <div className="container">
            { error === errorText ? <span className="errText">{error}</span> : null }

            <div className='HideButton' onClick={openModal}></div>
            <ModalWindow openModal={openModal} modalIsOpen={modalIsOpen} setLoginUnd={setLoginUnd} value={localStorage.getItem('uniqId')}/>
            {login === undefined ? <Preloader /> : 
                login ? <Connect changeLoginStatus={changeLoginStatus} /> : <LoginPage login={loginFunc} />}
        </div>
    )


} export default MainComponent