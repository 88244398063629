import React from 'react'
import QrCode from './QrCode'
function QrSreen(props) {

  const { links } = props;

  return (
    <div className='QrCont'>
      <QrCode type={'ios'} link={links.ios_link} />
      <QrCode type={'android'} link={links.android_link} />
    </div>
  )
}
export default QrSreen
