import React, { useState } from 'react'
import Modal from 'react-modal';
import '../styles/Modal.css';
import { IoMdClose } from "react-icons/io";
import { AiOutlineReload } from "react-icons/ai";


const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(14, 19, 29, 0.75)'
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 0
    }
};

Modal.setAppElement(document.getElementById('root'))


function ModalWindow(props) {
    const [value, setValue] = useState(props.value);


    const save = (value) => {
        localStorage.setItem('uniqId', value);
        props.setLoginUnd();
    }

    return (
        <Modal
            isOpen={props.modalIsOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={props.openModal}
            style={customStyles}
            //contentLabel="Example Modal"
        >
            <div className='ModalWindow'>
                <div className='ModalupButt'>
                    <div className='updButton' onClick={() => window.location.reload()}>
                        <AiOutlineReload size={20} color={'white'} />
                    </div>
                    <div className='closeButton' onClick={props.openModal}>
                        <IoMdClose size={20} color={'#0079C1'} />
                    </div>
                </div>
                <div className='ModalContainer'>
                    <div className='Modalhead'>
                        <span className='ModalText'>
                            Код экрана:
      </span>
                        <span className='ModalText'>
                            <input type="text" className='ModalInput' value={value} onChange={(evt) => { setValue(evt.target.value) }}></input>
                        </span>


                    </div>
                    <div className='Modalhead2'>
                        <div className='ModalButton' onClick={() => save(value)}>
                            Сохранить
                </div>
                    </div>
                </div>
            </div>
        </Modal>
    )


} export default ModalWindow