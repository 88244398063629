import getChartsColor from '../helpers/chartsColors'

const getAnswerStatistic = (answers, type) => {
  // console.log('answ_stat', source)
  const result = []
  const getValue = currAnswer => {
    if (type === 'pie')
      return currAnswer.percentage ? (currAnswer.percentage * 100).toFixed(1) : 0
    return currAnswer.count || 0
  }

  answers && answers.forEach(currAnswer => {
    result.push({
      title: String(currAnswer.text),
      value: getValue(currAnswer),
    })
  })
  return { source: result, colors: getChartsColor(result.length) }
}

export default getAnswerStatistic
