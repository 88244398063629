export const gridLinesColor = '#AAAAAA'
export const gridLabelsColor = '#AAAAAA'

export const gridLinesOptions = {
  lineWidth: 0.5,
  color: gridLinesColor,
  zeroLineColor: gridLinesColor,
  zeroLineWidth: 0.5,
}

export const legendOptions = {
  legend: {
    display: true,
    labels: {
      usePointStyle: true,
      fontFamily: 'DIN Pro Light',
      fontColor: 'white',
      fontSize:28,
      boxWidth: 10,
      padding: 14,
    },
    position: 'bottom',
  },
}

export const dataLabelsColor = {
  color: context => {
    const color = context.dataset.backgroundColor
    if (!color) return 'white'
    if (typeof color === 'string') return color
    const index = context.dataIndex
    return color[index]
  },
  font: {
    size: 34,
    family: 'DIN Pro',
  },
}
