import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels'

import getExtremeScaleValues from '../helpers/getExtremeScaleValues'

import {
  horizontalBarChartOptions,
  horizontalBarChartThickness,
  horizontalBarChartBorderWidth,
} from './setups/horizontalBarChartSetups'

const HorizontalBarChart = ({ source, colors }) => {
  const getChartDataObject = () => {
    const labelsArr = source.map(data => data.title)
    const valuesArr = source.map(data => data.value)
    const chartData = {
      labels: labelsArr,
      datasets: [{
        barThickness: horizontalBarChartThickness,
        borderWidth: horizontalBarChartBorderWidth,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        data: valuesArr,
      }],
    }

    return chartData
  }

  return (
    <div className='chart_horizontal_bar__wrapper'>
      {console.log('source',source)}
      <HorizontalBar
        data={getChartDataObject()}
        options={horizontalBarChartOptions(getExtremeScaleValues(source))}
      />
    </div>
  )
}

export default HorizontalBarChart
