import React, { useState, useEffect } from 'react'
import PieChart from '../charts/PieChart'
import VerticalBarChart from '../charts/VerticalBarChart'
import HorizontalBarChart from '../charts/HorizontalBarChart'
import GroupedBarChart from '../charts/GroupedBarChart'
import QrSreen from '../charts/QrSreen'
import HorizontalGroupedBarChart from '../charts/HorizontalGroupedBarChart'
import packDatasets from '../helpers/packDatasets'
import getAnswerStatistic from '../statistic/getAnswerStatistic'

function ShowCharts(props) {

    const { data } = props;

    useEffect(() => {
        DataSet(data?.graph_data?.name, data?.graph_data?.grouped)
    });

    const [dataType, setDataType] = useState(undefined)

    const DataSet = (name, grouped) => {
        if (grouped === true) {
            switch (name) {
                case 'answer_statistic':
                    setDataType();
                    break;
                case 'gender_statistic':
                    setDataType(data.poll.categorized[0]);
                    break;
                case 'district_statistic':
                    setDataType(data.poll.categorized[1]);
                    break;
                case 'age_statistic':
                    setDataType(data.poll.categorized[2]);
                    break;
                default:
                    break;
            }
        }
        else {
            setDataType(data?.poll?.answers);
        }
    }

    const NameSet = (name) => {
        switch (name) {
            case 'answer_statistic':
                return 'Статистика по ответам';
            case 'gender_statistic':
                return 'Статистика по полу';
            case 'age_statistic':
                return 'Статистика по возрасту';
            case 'district_statistic':
                return 'Статистика по региону';
            case 'QR':
                return 'Ссылки на приложения';
            default:
                return '';
        }
    }


    const SetType = (type, grouped) => {
        if (grouped === true) {
            switch (type) {
                case 'bar':
                    return <GroupedBarChart source={packDatasets(dataType)} />;
                case 'horizontal_bar':
                    return <HorizontalGroupedBarChart source={packDatasets(dataType)} />
                default:
                    return null;
            }
        }
        else {
            switch (type) {
                case 'bar':
                    return <VerticalBarChart {...getAnswerStatistic(data.poll.answers)} />;
                case 'horizontal_bar':
                    return <HorizontalBarChart {...getAnswerStatistic(data.poll.answers)} />;
                case 'pie':
                    return <PieChart {...getAnswerStatistic(data.poll.answers, 'pie')} />;
                case 'QR':
                    return <QrSreen links={data?.graph_data.content} />;
                default:
                    return null;
            }
        }
    }

    return (
        <div className="InputContainer2">
            <span className="Title">{data ? data?.poll?.title : null}</span>
            <span className="SubTitle">{dataType ? NameSet(data?.graph_data.name, data?.graph_data.grouped) : null}</span>
            {dataType ? SetType(data?.graph_data.type, data?.graph_data.grouped) : null}
        </div>
    )

} export default ShowCharts