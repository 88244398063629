import React from 'react'
import './styles/App.css';
import './styles/charts.css';
import MainComponent from './MainComponent'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path='/' component={MainComponent}/>
                {/* <Route path="/">
                    <Redirect to='/presenter' />
                </Route> */}
            </Switch>
        </Router>
    );
}

export default App;
