import React, { Component } from 'react'
import Preloader from './Preloader'
import api from '../api/index'
import ShowCharts from './ShowCharts'


const webSocketAdress = 'wss://ws.api-informer.liis.su:443';
//const webSocketAdress = 'wss://dev.ws.api-informer.liis.su:443';

class Connect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            data: [],
            id:undefined
        };
    }
    
    componentDidMount() {
        this.webSocket();
        window.addEventListener("beforeunload",  this.ws.close());
        this.setState({id:localStorage.getItem('uniqId')})
    }

    
 
    check = () => {
        if (!this.ws || this.ws.readyState === WebSocket.CLOSED) this.webSocket();
        //console.log('check');
    };

    webSocket = () => {
        //console.log('ws')
        //const { isReady } = this.state;
        const data = {
            type: 'default',
            data: {
                presenter: this.state.id
            }
        }
        let connectInterval;
        //if (!isReady) {
            //console.log('isReady', isReady);
            this.ws = new WebSocket(webSocketAdress);

            this.ws.onopen = () => {
                this.webSocketReconnect = setTimeout(() => {
                    this.ws.close();
                    //console.log('reconnect');
                }, 7200000);

                clearTimeout(connectInterval);
                //console.log('connected');
                try {
                    this.ws.send(JSON.stringify(data));
                    console.log('send', data);
                } catch (error1) {
                   // console.log('catch', error1);
                }
            };


            this.ws.onerror = (err2) => {
                //console.log('onerror', err2.message);
                try {
                    this.ws.close();
                    //console.log('onerrorClose');
                } catch (err3) {
                    console.log('catch2', err3);
                }
            };

            this.getTicket = async () => {
                const id = this.state.id;
                //console.log(id);

                await api.getTicket(id).then(res => {
                    // console.log(res)
                    // console.log(res.data.ticket);
                    const ticket = {
                        type: "session_init",
                        data: {
                            ticket: res.data.ticket,
                            presenter: id
                        }
                    }

                    this.ws.send(JSON.stringify(ticket));
                })
                    .catch((err) => {
                        //console.log(err)
                    });

            }

            this.ws.onmessage = (evt) => {
                let messagePars;
                try {
                    messagePars = JSON.parse(evt.data);
                    console.log('onmessage', messagePars);
                    this.setStatus(messagePars.type, messagePars.data)
                } catch (error) {
                    //console.log(error);
                }
            };
            this.ws.onclose = () => {
                //console.log('disconnected');
                connectInterval = setTimeout(this.check, 1000);

                this.setState({ isReady: false })
            };
       // }
    }

    setStatus = (status, data) => {
        switch (status) {
            case 'session_init':
                this.setState({isReady: false})
                this.getTicket()
                break;
            case 'update':
                this.setState({data: data, isReady: true})
                break;
            case 'session_stop':
                this.setState({data: null, isReady: true})
                break;

            case 'unattached':
                this.setState({ data: null })
                localStorage.clear()
                this.props.changeLoginStatus()
                break;
            default:
                break;
        }
    };

    render() {
        const { isReady, data } = this.state;
        return (
            <div className="InputContainer">
                {isReady ? <ShowCharts data={data} /> : <Preloader />}
            </div>

        )
    }
}


export default Connect