export const chartsColors = ['#0079C1', '#B958F5', '#31CB81', '#CB3B3B', '#F7A833']

const getChartsColor = length => {
  if (length <= chartsColors.length) return chartsColors
  let resultColorsArray = chartsColors
  const iterations = Math.ceil(length / chartsColors.length) - 1
  for (let i = 0; i < iterations; i++)
    resultColorsArray = resultColorsArray.concat(chartsColors)
  return resultColorsArray
}

export const getColor = index => {
  const chartsColorsLength = chartsColors.length
  if (index < chartsColorsLength) return chartsColors[index]
  const newIndex = index % chartsColorsLength
  return chartsColors[newIndex]
}

export default getChartsColor
