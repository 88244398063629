import React from 'react'

function LoginPage(props) {

    const { login } = props;
    return (
        
           
            <div className="button" onClick={login}>
                <span >Активировать</span>
            </div>
  
    )
}


export default LoginPage