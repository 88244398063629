import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'

import horizontalGroupedBarChartOptions from './setups/horizontalGroupedBarChartSetups'
// import {
//   horizontalGroupedBarThickness,
// } from './setups/horizontalGroupedBarChartSetups'

const HorizontalGroupedBarChart = ({ source }) => {
  const getMaxVal = () => {
    let maxVal = 0
    source.datasets.forEach(item =>
      maxVal = (Math.max(...item.data) > maxVal) ? Math.max(...item.data) : maxVal)
    return maxVal + maxVal * 0.1
  }


  return (
   
    <div className='group_bar_chart__wrapper'>
      <div className='full_height'>
        <HorizontalBar
          data={source}
          options={horizontalGroupedBarChartOptions(getMaxVal())}
        />
      </div>
    </div>
  )
}

export default HorizontalGroupedBarChart
