import axios from 'axios';
import { hostApiServer, hostApiTicket, portApiServer } from './config.json';

export default {
  receive(serialNumber) {
    const request = axios.create({
      headers: {
        Authorization: 'none',
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'Cache-Control': 'no-store, no-cache, must-revalidate'
      }
    });
    const dataToSend = {
      serial_number: serialNumber,
    };
    return request.post(`${hostApiServer}:${portApiServer}/presenter/receive`, dataToSend);
  },

  getTicket(serialNumber) {
    const request = axios.create({
      headers: {
        Authorization: 'none',
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'Cache-Control': 'no-store, no-cache, must-revalidate'
      }
    });
    const dataToSend = {
      presenter: serialNumber,
    };
return request.post(`${hostApiTicket}:${portApiServer}/presenter_ticket`, dataToSend);
  }

};
