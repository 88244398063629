import React from 'react'
import { Bar } from 'react-chartjs-2'

// eslint-disable-next-line
//import ChartDataLabels from 'chartjs-plugin-datalabels'

import getExtremeScaleValues from '../helpers/getExtremeScaleValues'

import {
  verticalBarChartOptions,
  verticalBarChartThickness,
  verticalBarChartBorderWidth,
} from './setups/verticalBarChartSetups'

const VerticalBarChart = ({ source, colors }) => {
  const getChartDataObject = () => {
    const labelsArr = source.map(data => data.title)
    const valuesArr = source.map(data => data.value)

    return {
      labels: labelsArr,
      datasets: [{
        barThickness: verticalBarChartThickness,
        borderWidth: verticalBarChartBorderWidth,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        data: valuesArr,
      }],
    }
  }

  return (
    <div className='chart_vertical_bar__wrapper'>
      <Bar
        data={getChartDataObject()}
        options={verticalBarChartOptions(getExtremeScaleValues(source))}
      />
    </div>
  )
}

export default VerticalBarChart
