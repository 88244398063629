import React from 'react'
import apple from '../svg/apple.svg'
import android from '../svg/android.svg'

const QRCode = require('qrcode.react');

function QrCode(props) {

  const { link,type } = props;

  return (
   
      <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
        <div style={{ backgroundColor: 'white', padding: 15, borderRadius: 20, margin: 80 }}>
          <QRCode
            size={256}
            level={'H'}
            fgColor={'#191F28'}
            renderAs='svg'
            value={link}
            imageSettings={{
              src: type === 'ios' ? apple : android,
              height: 60,
              width: 60,
              excavate: true,
              color: 'black'
            }}
          />
        </div>
        <div className='TextQr'>
          <span style={{ fontSize: 28, color: '#A7A7A7' }}>{type}</span>
        </div>
      </div>
  )
}
export default QrCode
