import { gridLinesOptions, gridLabelsColor, gridLinesColor, dataLabelsColor } from './globalSetups'

export const horizontalBarChartOptions = (extremeScaleValues) => ({
  plugins: {
    datalabels: {
      ...dataLabelsColor,
      display: true,
      align: 'end',
      anchor: 'end',
      offset: 5,
    },
  },
  scales: {
    xAxes: [{
      ticks: {
        fontFamily: 'DIN Pro Light',
        fontSize:28,
        fontColor: gridLabelsColor,
        suggestedMin: 0,
        suggestedMax: extremeScaleValues.maxValue,
      },
      gridLines: gridLinesOptions,
    }],
    yAxes: [{
      ticks: {
        fontColor: 'white',
        fontSize:28,
        callback: value => value.length >= 15 ? `${value.substring(0, 14)}...` : value,
      },
      gridLines: { ...gridLinesOptions, zeroLineColor: gridLinesColor, zeroLineWidth: 0.5 },
    }],
  },
  legend: { display: false },
  tooltips: {
    enabled: true,
    displayColors: false,
  },
  maintainAspectRatio: false,
})

export const horizontalBarChartThickness = 50
export const horizontalBarChartBorderWidth = 0
